<template>
  <v-sheet class="pa-6">
    <v-row no-gutters justify="center" align="center">
      <FilterSchoolYear
        :dataFilter="dataFilter"
        :withInitial="true"
        notMultiple
        @handler="getFilter"
      />

      <v-spacer></v-spacer>
      <!-- <v-btn class="gradient-primary" dark>
        {{ $t("app.grade") }}
        <v-icon small class="ml-2">mdi-trophy</v-icon>
      </v-btn> -->
    </v-row>

    <v-row class="mt-4">
      <v-col cols="12">
        <v-data-table
          :headers="tableHeaders"
          :items="dataTable"
          :loading="loading"
          :options.sync="options"
          class="elevation-0"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item.teachers="{ item, value }">
            <v-row v-if="value.length > 1" no-gutters align="start">
              <span v-if="!item.viewMore">
                <span>{{ value[0].name }}</span>
              </span>
              <span v-else>
                <div v-for="ht in value" :key="ht.id" class="my-2">
                  {{ ht.name }}
                </div>
              </span>

              <v-spacer></v-spacer>

              <v-btn
                small
                icon
                @click="item.viewMore = !item.viewMore"
                :class="item.viewMore ? 'mt-2' : ''"
              >
                <v-icon v-if="item.viewMore">mdi-chevron-up</v-icon>
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-btn>
            </v-row>
            <v-row v-if="value.length == 1" no-gutters>{{
              value[0].name
            }}</v-row>
          </template>
          <template v-slot:item.action="{ item }">
            <v-row justify="space-between">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon small @click="toDetail(item)">
                    <v-icon small>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("score.to_detail") }}</span>
              </v-tooltip>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <Pagination
          :length="length"
          :total="total"
          :current-page="queryData.page"
          :limit="queryData.limit"
          :handler="paginateChange"
        />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import i18n from "@/i18n";
import Pagination from "@/components/Pagination";
import FilterSchoolYear from "../../components/filterSchooYear";
import { getListSubject } from "@/api/admin/academic/score.js";

export default {
  metaInfo: {
    title: i18n.t("routes.score"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    Pagination,
    FilterSchoolYear
  },
  data() {
    return {
      list: {
        school_year: [],
        grade: [],
        class: []
      },
      filter: {
        school_year: [],
        grade: [],
        class: []
      },
      selectAllGrade: false,
      tableHeaders: [
        {
          text: i18n.t("score.table.subject"),
          align: "left",
          value: "lesson_text",
          width: 200
        },
        {
          text: i18n.t("score.table.kkm"),
          value: "kkm"
        },
        {
          text: i18n.t("score.table.homeroom"),
          value: "teachers"
        },
        {
          text: i18n.t("app.action"),
          sortable: false,
          value: "action",
          width: 50
        }
      ],
      queryData: {
        page: 1,
        limit: 10,
        sort: "created_at",
        order: "ASC",
        search: "",
        type: this.$route.name.toLowerCase()
      },
      total: 0,
      length: 0,
      dataTable: [],
      options: {},
      loading: false,
      dataFilter: {}
    };
  },
  watch: {
    options: {
      handler(param) {
        if (param.sortBy.length != 0) {
          this.loading = true;
          this.queryData.sort = [param.sortBy[0]];
          this.queryData.page = param.page;
          this.queryData.limit = param.itemsPerPage;
          this.getData();
        }
      }
    }
  },
  mounted() {
    this.dataFilter = {};
    this.$nextTick(() => {
      this.dataFilter = this.$route.query;
    });
  },
  methods: {
    getFilter(res) {
      switch (res.type) {
        case "school_year":
          this.filter.school_year = res.filter.school_year;
          this.getData();
          break;
        case "select_grade":
          this.filter.grade = res.filter.grade;
          this.getData();
          break;
        case "select_class":
          this.filter.class = res.filter.class;
          this.getData();
          break;
        default:
          break;
      }
    },
    toDetail(param) {
      this.$router.push({
        path: `score/${param.lesson}/${param.classroom}`,
        query: { ...this.filter }
      });
    },
    handleClickAllGrade() {
      this.selectAllGrade = !this.selectAllGrade;
      this.filter.grade = [];
      if (this.selectAllGrade)
        this.filter.grade = this.master.gradeList.map(r => r.grade);
      else this.selectAllGrade = false;
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.getData();
    },
    getData() {
      const newData = [];
      if (this.filter.class && Number.isInteger(this.filter.class)) {
        this.loading = true;
        this.queryData.classroom = this.filter.class;
        this.queryData.school_year = this.filter.school_year;
        getListSubject(this.queryData).then(res => {
          this.loading = false;
          const r = res.data.data;
          this.total = r.total;
          this.length = r.last_page;

          r.data.map(d => newData.push({ ...d, viewMore: false }));
          this.dataTable = newData;
        });
      }
    }
  }
};
</script>
